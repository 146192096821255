import React, { ReactElement } from 'react'
import { Link } from 'gatsby'
import styled from 'styled-components'

const SiteHeaderDiv = styled.div`
    border-bottom: 1px solid;
    padding: 10px 0;
    text-align: center;
    max-width: 100%;

    @media only screen and (min-width: 768px) { 
        &.isMainPage {
            padding: 30px 0;    
        }
    }
`

const LogoLink = styled(Link)`
   font-size: 28px;
   letter-spacing: 3px;
   line-height: 1;
   margin: 0 0 10px;
   color: black;

    @media only screen and (min-width: 768px) { 
        &.isMainPage {
            font-size: 78px;        
        }
    }
`;

export default function SiteHeader({ mainPage }): ReactElement {
    return (
        <SiteHeaderDiv className={mainPage ? 'isMainPage' : ''}>
            <h1>
                <LogoLink to="/" className={mainPage ? 'isMainPage' : ''}>Plastic Fake Tree</LogoLink>
            </h1>
        </SiteHeaderDiv>
    )
}
