import React from 'react';
import styled from 'styled-components';

const StyledBurger = styled.button`
  /* position: absolute;
  top: 5%;
  left: 2rem; */
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 1.25rem;
  height: 1rem;
  background: transparent;
  border: none;
  cursor: pointer;
  padding: 0;
  z-index: 10;
  
  &:focus {
    outline: none;
  }
  
  div {
    width: 1.25rem;
    height: 0.25rem;
    background: black;
    border-radius: 10px;
    transition: all 0.3s linear;
    position: relative;
    transform-origin: 1px;
  }
`;

const CloseX = styled.button`
  width: 1.25rem;
  height: 1.25rem;
  padding: 0;
  border: none;
  background: transparent;
`;

const mdivStyle = {
  height: "25px",
  width: "4px",
  borderRadius: "10px",
  marginLeft: "8px",
  marginTop: "-4px",
  backgroundColor: "black",
  transform: "rotate(45deg)",
  zIndex: 1
};

const mdStyle = {
  height: "25px",
  width: "4px",
  borderRadius: "10px",
  backgroundColor: "black",
  transform: "rotate(90deg)",
  zIndex: 2
};

const Burger = ({ open, setOpen }) => {
  return !open ?
    ( <StyledBurger onClick={() => setOpen(!open)}>        
          <div />
          <div />
          <div />
      </StyledBurger>
    ) :
    ( <CloseX onClick={() => setOpen(!open)}>
        <div style={mdivStyle}>
          <div style={mdStyle}></div>
        </div>
      </CloseX>
    )
}

export default Burger;