import { createGlobalStyle } from 'styled-components'
import './reset.css'

const GlobalStyles = createGlobalStyle`  
  body {    
    font-family: 'Roboto Condensed', sans-serif;
    font-size: 1rem;    
    line-height: 15px;
  }

  @media screen and (min-width: 768px) {
    body {
      font-size: 1.4rem;    
      line-height: 24px;
    }
  }

  html {
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    //min-width: 300px;
    overflow-x: hidden;
    overflow-y: scroll;
    text-rendering: optimizeLegibility;

    &.mobile-menu-open {
      overflow: hidden;
    }
  }

  a {
    text-decoration: none;  
  }

  div, h2, p, figure {
    margin: 0;
    padding: 0;
  }

  .column {
    flex: 1;
    flex-direction: column;
  }

  .article {
    background: white;
    /* box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24); */  
    color: #666;
    display: flex;
    //height: 100%;
    flex: 1;
    flex-direction: column;
    flex-basis: auto;
  }

  @media only screen and(min-width: 600px) {
      .responsive-article {
        flex-direction: row;
        display: block;
        flex-direction: column;
    }
  }
`

export default GlobalStyles;