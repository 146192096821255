import { Link } from 'gatsby'
import React, { ReactElement, useEffect, useState } from 'react'
import styled from 'styled-components'
import QDistinctSubCategories from '../queries/QDistinctSubCategories';
import Burger from './Burger';

const StyledNav = styled.nav`
	display: none;
	margin-bottom: 0.5em;		
	font-size: 80%;

	@media only screen and (min-width: 768px) { 
		display: flex;
	}
`;

interface IMobileNavProps {
	isOpen: boolean
}

const StyledMobileNav = styled.nav<IMobileNavProps>`
	display: flex;
	margin-bottom: 0.5em;			
	position: absolute;
	top: 1.1em;
	left: 1em;
	font-size: 80%;
	overflow: visible;
	height: ${props => props.isOpen ? "100%" : "1em"};

	@media only screen and (min-width: 768px) { 
		display: none;
	}
`;

const MobileMenu = styled.div<IMobileNavProps>`
	display: ${props => props.isOpen ? "block" : "none"};	
	z-index: 1000;
	width: 100vw;
	height: 100vh;
	position: fixed;
	bottom: 0;
	top: 3em;	
	right: 0;	
	opacity: 0.98;
	left: 0;
	background: lightslategray;	
	color: white;
`;

const MobileMenuContentsContainer = styled.div`
	flex-direction: column;
	display: flex;
	padding: 1em;

	& a {
		color: white;
		line-height: 1.5em;
	}
`;

const StyledLink = styled(Link)`
	margin-right: 8px;
	color: black;
	text-transform: uppercase;

	&:hover {
		text-decoration: underline;
		background: lightgrey;
	}
`;

const StyledAboutLink = styled(StyledLink)`
	margin-left: auto;
	margin-right: 0;
`;

const activeStyles = {
	textDecoration: "underline"
};

const MenuItems = (distinctCategories) => {
	return distinctCategories.map(subcategory => {
		return <StyledLink partiallyActive={true} activeStyle={activeStyles} to={`/${subcategory}`}>{subcategory}</StyledLink>;
	});
};

export default function TopNavigation(): ReactElement {
	const [isOpen, setOpen] = useState(false)

	const setMobileMenuOpen = (isOpen) => {
		setOpen(isOpen);

		if (isOpen) {
			document.documentElement.className = "mobile-menu-open";
		}
		else {
			document.documentElement.className = "";
		}
	};

	useEffect(() => {
		return () => {
			setMobileMenuOpen(false);
		}
	}, []);

	return (
		<QDistinctSubCategories>{
			(distinctCategories) => {
				return <>
					<StyledNav>
						{MenuItems(distinctCategories)}
						<StyledAboutLink activeStyle={activeStyles} to="/about">ABOUT</StyledAboutLink>
					</StyledNav>
					<StyledMobileNav isOpen={isOpen}>
						<Burger setOpen={setMobileMenuOpen} open={isOpen}></Burger>
						<MobileMenu isOpen={isOpen}>
							<MobileMenuContentsContainer>
								<span>News by category</span>
								{MenuItems(distinctCategories)}
								<br/>
								<br/>
								<Link activeStyle={activeStyles} to="/about">ABOUT</Link>
							</MobileMenuContentsContainer>
						</MobileMenu>						
					</StyledMobileNav>
				</>
			}
		}
		</QDistinctSubCategories>
	)
}
