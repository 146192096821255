import { graphql, StaticQuery } from 'gatsby'
import React, { ReactElement } from 'react'

// interface QProps {
// 	children: (data: Boolean | any[]) => React.ReactNode
// }

export default function QDistinctSubCategories({ children }): ReactElement {
	return <StaticQuery
		query={graphql`
				query subCategoriesQuery3 {
					allCosmicjsArticles {    
						distinct(field: metadata___sub_category)
					}
				}
		  `}
		render={data => {
			return children(data && data.allCosmicjsArticles.distinct)
		}}
	/>
}
