import { Link } from "gatsby";
import React from "react"
import styled from "styled-components";
import GlobalStyles from "../GlobalStyles"
import SEO from "./SEO";
import SiteHeader from "./SiteHeader"
import TopNavigation from "./TopNavigation"

const MainContainer = styled.main`
  margin: 0 auto;
  max-width: 1460px;
  padding: 10px;  
`;

const Footer = styled.footer`
	display: flex;
	flex-direction: column;
	align-items: center;	
	background-color: rgb(245, 245, 245);	
	padding-top: 1em;	
	padding-bottom: 1em;	
`;

const StyledAboutLink = styled(Link)`
	padding-bottom: 1.0em;
`;

const ContentContainer = styled.div`
	flex: 1 0 auto;
`;

const BodyContainer = styled.div`
	display: flex;
	min-height: 100vh;
    flex-direction: column;
`;

export default function Layout({ children, mainPage = false }) {
	return (
		<BodyContainer>
			<SEO></SEO>
			<GlobalStyles />
			<ContentContainer>
				<SiteHeader mainPage={mainPage} />
				<MainContainer>
					<TopNavigation />
					{children}
				</MainContainer>
			</ContentContainer>
			<Footer>
				<StyledAboutLink to="/about" style={{ color: "black" }}>About</StyledAboutLink>
				<div>
					<span> © Plastic Fake Tree 2020</span>
				</div>
			</Footer>
		</BodyContainer>
	)
}